<template>
  <div>
    <fieldset style="width:97%">
      <legend>搜索信息</legend>
      <el-row :gutter="20">
        <el-col :span="4">
          <el-input v-model="form.clothesName" placeholder="款式编号"></el-input>
        </el-col>
        <el-col :span="3">
          <el-select v-model="form.styleType" filterable placeholder="款式类型" clearable class="searchParam" size="medium">
            <el-option
                v-for="item in styleTypeIdArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <el-date-picker
              v-model="form.date"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              type="daterange"
              start-placeholder="档期开始日期"
              end-placeholder="档期结束日期">
          </el-date-picker>
        </el-col>
      </el-row>
      <br>
      <el-row>
        <el-col :span="4">
          <el-select v-model="form.shopId" placeholder="选择店铺" clearable @change="shopChange">
            <el-option
                v-for="item in shopIdArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select v-model="form.positionId" placeholder="选择位置" clearable>
            <el-option
                v-for="item in positionIdArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" @click="search">搜索</el-button>
        </el-col>
      </el-row>
    </fieldset>
    <div class="infinite-list-wrapper">
      <el-row :gutter="20" style="margin: 0 auto;" class="list" v-infinite-scroll="load" :infinite-scroll-disabled="disabled">
        <el-col :span="4" v-for="item in styleList" :key="item.styleId" style="margin: 1% 0 0 0" class="list-item">
          <div style="text-align: center">
            <el-image
              style="width: 250px; height: 300px" :preview-src-list="['https://clothes-image-1304365928.cos.ap-shanghai.myqcloud.com/'+item.styleImage]"
              :src="'https://clothes-image-1304365928.cos.ap-shanghai.myqcloud.com/'+item.styleImage"
              fit="contain"></el-image>
            <p style="margin: 2% 0;font-size: 15px">{{item.styleAllName}}</p>
            <el-row style="padding: 0 5%;margin: 2% 0">
              <span style="float: left">档期数:{{item.scheduleCount}}</span>
              <span style="float: right">档期天数:{{item.scheduleCountDay}}</span>
            </el-row>
            <el-row style="padding: 0 5%;margin: 3% 0">
              <span style="float: left">相同数:{{item.sameCount}}</span>
              <a @click="openStyleSame(item.styleId)" style="float: right" href='javascript:;'>查看更多</a>
            </el-row>
          </div>
        </el-col>
      </el-row>
      <p v-loading="loading" element-loading-text="加载中"></p>
      <p v-if="noMore">没有更多了</p>
    </div>
    <clothesScheduleSame v-if="samePageState" :searchDate="form"  :state="samePageState" @close="close"/>
  </div>

</template>

<script>
import clothesScheduleSame from "@/pages/statistic/clothes-analysis/clothes-schedule-same"
export default {
  name: "clothes-schedule",
  data() {
    return {
      form:{
        styleType:"",
        dateString:"",
        clothesName:"",
        date:"",
        shopId:"",
        positionId:"",
        tenantCrop: localStorage.getItem("tenantCrop")
      },
      page:1,
      shopIdArray:[],
      positionIdArray:[],
      styleList:[],
      styleTypeIdArray:[],
      samePageState:false,
      total:0,
      loading: true,
      disabled:true,
      noMore:false,
    }
  },
  created() {
    this.queryShopIdArr();
    this.queryStyleList();
    this.queryStyleTypeIdsArr();
  },
  components:{clothesScheduleSame},
  methods: {
    search(){
      this.form.dateString=this.$dateUtils.stringArrayToString(this.form.date)
      this.styleList=[];
      this.page=1;
      this.total=0;
      this.queryStyleList()
    },
    //查询店铺
    queryShopIdArr(){
      this.$selectUtils.shopIdsIsValid().then(response=>{
        this.shopIdArray=JSON.parse(response.data.data)
      })
    },
    //店铺更改
    shopChange(val){
      if (val===""){
        this.positionIdArray=[];
        this.form.positionId="";
      }else {
        this.$selectUtils.queryPositionIdsByShopId(val).then(response=>{
          this.positionIdArray=JSON.parse(response.data.data)
        })
      }
    },
    //查询款式列表
    queryStyleList(){
      this.$axios({
        method: "GET",
        url: "/clothesAnalysis/queryClothesSchedule",
        params: {
          page: this.page,
          limit: 30,
          clothesName: "%"+this.form.clothesName+"%",
          styleType: this.form.styleType,
          date: this.form.dateString,
          positionId: this.form.positionId,
          shopId: this.form.shopId,
          tenantCrop: this.form.tenantCrop
        }
      }).then(response => {
        this.styleList.push(...response.data.data.list)
        this.total=response.data.data.total
        this.disabled=false;
        this.loading=false;
      })
    },
    //打开相同款式
    openStyleSame(styleId){
      this.form.styleId=styleId
      this.samePageState=true;
      console.log(this.form)
    },
    //查询款式类型
    queryStyleTypeIdsArr(){
      this.$selectUtils.queryStyleTypeIds().then(response=>{
        this.styleTypeIdArray=JSON.parse(response.data.data);
      })
    },
    //家宅
    load() {
      this.disabled=true;
      if (this.styleList.length>=this.total){
        this.noMore=true;
        this.loading=false;
        return;
      }
      this.loading = true;
      this.noMore=false;
      this.page++;
      setTimeout(() => {
        this.queryStyleList();
      }, 1000)
    },
    close: function () {
      this.samePageState=false;
    },
  }
}
</script>

<style scoped>
span{
  font-size: 13px;
}
p{
  margin: 5% 0;
  font-size: 23px;
  text-align: center;
}
</style>